import type { IconProps } from '~/types'

export const DogIcon = (props: IconProps) => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      width="62px"
      height="53px"
      viewBox="0 0 62 53"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Mission@2x</title>
      <g
        id="Tucker-Tub---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Our-Story"
          transform="translate(-765.000000, -1083.000000)"
          stroke="#86CB92"
          strokeWidth="2.5"
        >
          <g id="Mission" transform="translate(766.567080, 1084.262401)">
            <path
              d="M16.2912317,50 L8.59630222,50 C6.83192003,42.3976606 5.54733403,34.6918959 4.75014515,26.9283205 C4.74806151,24.3762106 5.76024115,21.927876 7.56387222,20.1222781 C9.36750329,18.3166801 11.8147333,17.3018326 14.3668439,17.3011379 L31.6706035,17.3011379 C33.5348634,17.4540604 35.3213383,16.5230949 36.2639652,14.9074511"
              id="Shape"
            />
            <path
              d="M43.2195522,8.64663625 C43.2195522,12.3643228 44.1817461,15.3793718 39.3707766,15.3793718 C34.559807,15.3793718 33.6002349,13.4602276 35.5193791,7.69230769 C36.6913156,4.17912013 37.8003293,0 45.1360746,0 C48.6389022,0.169047167 51.6861196,2.45316244 52.8310041,5.76791988 L58.598924,7.68968591 C58.598924,7.68968591 60.761894,18.3891773 50.9039945,17.3063814 C48.9822285,25.0013109 50.9039945,30.7718526 45.1360746,34.6101411 L45.1360746,49.9973782 L48.9848503,49.9973782"
              id="Shape"
            />
            <path
              d="M25.9079272,28.8422212 C25.9079272,28.8422212 25.9079272,36.5371506 16.2912317,36.5371506 L14.0469865,50"
              id="Shape"
            />
            <polyline
              id="Shape"
              points="45.1413182 50 37.4490105 50 35.5246227 34.6127628 31.6784689 34.6127628"
            />
            <path
              d="M6.67977975,21.1499135 C6.67977975,21.1499135 -2.9447811,19.2281474 0.91185987,7.69230769"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
