import type { IconProps } from '~/types'

export const TeamIcon = (props: IconProps) => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      width="58px"
      height="51px"
      viewBox="0 0 58 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Team@2x</title>
      <g
        id="Tucker-Tub---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Our-Story"
          transform="translate(-178.000000, -1477.000000)"
          stroke="#86CB92"
          strokeWidth="2.5"
        >
          <g id="Team" transform="translate(179.785000, 1478.785714)">
            <path
              d="M40.4761905,47.6190476 L40.4761905,44.047619 C40.4761905,34.6357143 33.5738095,33.1166667 27.0619048,30.7142857 C24.897619,29.9119048 25.2547619,24.2619048 26.2142857,23.2071429 C28.4595238,20.7333333 30.3690476,17.8333333 30.3690476,10.8261905 C30.3690476,3.75714286 25.7571429,0 20.2380952,0 C14.7214286,0 10.1071429,3.75714286 10.1071429,10.8214286 C10.1071429,17.8261905 12.0119048,20.7261905 14.2642857,23.202381 C15.2190476,24.2547619 15.5785714,29.9071429 13.4166667,30.7095238 C7.02142857,33.0738095 0,34.5642857 0,44.047619 L0,47.6190476 L40.4761905,47.6190476 Z"
              id="Stroke-1"
            />
            <path
              d="M48.5769048,47.6214286 L54.762619,47.6214286 L54.762619,45.5904762 C54.762619,38.3928571 49.4840476,37.2309524 44.5054762,35.3880952 C42.8388095,34.7761905 43.1221429,30.4547619 43.8554762,29.65 C46.215,27.0928571 47.3721429,23.65 47.0340476,20.1857143 C47.2388095,18.0238095 46.5054762,15.8833333 45.0245238,14.302381 C43.5411905,12.7190476 41.4530952,11.847619 39.2864286,11.9071429 C38.7054762,11.902381 38.1269048,11.9571429 37.562619,12.0738095"
              id="Stroke-3"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
