
const MaintenancePage = () => {
  return (
    <>
        <section 
        // style={{ backgroundColor:'#fff7f0', height:'100vh' }}
         className="container bg-with-height">
          <h1>We are <br/> Under Maintenance</h1>
          <p 
          className="maintenance-page-header"
          // style={{ fontSize:14 }}
          >We are improving our website.</p>
          <p 
          className="maintenance-page-header"
          // style={{ fontSize:14 }} 
          >We will be back soon.</p>
        </section>
    </>
  )
}

export default MaintenancePage
