import type { IconProps } from '~/types'

export const ShoppingBagIcon = (props: IconProps) => {
  const { className = '' } = props

  return (
    <svg
      {...props}
      className={`${className}`}
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shopping Bag</title>
      <g
        id="Tucker-Tub---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Cart-Drawer"
          transform="translate(-1341.000000, -76.000000)"
          stroke="#014032"
          strokeWidth="2.5"
        >
          <g id="Bag" transform="translate(1342.951393, 77.750000)">
            <path
              d="M8.04160711,22.5000001 L1.49960711,22.5000001 C1.06803338,22.5000001 0.657419772,22.3138873 0.372711738,21.9895461 C0.0880037035,21.6652048 -0.0432097729,21.2339491 0.0126071138,20.8060001 L1.87060711,6.55600006 C1.96808058,5.80866865 2.6049459,5.24969949 3.35860711,5.24999994 L4.47460711,5.24999994 C5.2278877,5.25020183 5.86418285,5.80904612 5.96160711,6.55600006 L8.04160711,22.5000001 Z"
              id="Shape"
            />
            <path
              d="M13.2916071,5.25000006 L18.7246071,5.25000006 C19.4778877,5.25020183 20.1141828,5.80904612 20.2116071,6.55600006 L22.0706071,20.8060001 C22.1264466,21.2341221 21.9951039,21.6655444 21.7101726,21.9899218 C21.4252414,22.3142992 21.0143553,22.5000002 20.5826071,22.5000002 L8.04160711,22.5000002"
              id="Shape"
            />
            <line x1="4.47460711" y1="5.25000006" x2="11.0416071" y2="5.25000006" id="Shape" />
            <path
              d="M17.0416071,9.75000006 L17.0416071,3 C17.0416071,1.92820328 16.4698104,0.937822198 15.5416071,0.401923799 C14.6134039,-0.1339746 13.4698103,-0.1339746 12.5416071,0.401923799 C11.6134039,0.937822198 11.0416071,1.92820328 11.0416071,3 L11.0416071,9.75000006"
              id="Shape"
            />
            <path
              d="M8.62660711,0.503000063 C7.79121776,1.06035965 7.29011856,1.99874768 7.29160381,3.00300006 L7.29160381,5.25000006"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
