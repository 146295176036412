import { useState } from 'react';

import clsx from 'clsx';
import Headroom from 'react-headroom';

import { Banner } from '../../banner';
import { NavMenu } from './NavMenu';

export const Navbar = () => {
  const [isUnfix, setIsUnfix] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  return (
    <Headroom
      className="relative z-[100]"
      onPin={() => {
        setIsSticky(true);
        setIsUnfix(false);
      }}
      onUnpin={() => {
        setIsSticky(false);
      }}
      onUnfix={() => {
        setIsSticky(false);
        setIsUnfix(true);
      }}
    >
      <div>
        <div className={clsx(!isUnfix && 'hidden')}>
          <Banner />
        </div>
        <nav className={clsx('w-full bg-colorFifteen lg:top-[40px]', isSticky && 'shadow-md')}>
          <NavMenu />
        </nav>
      </div>
    </Headroom>
  );
};
