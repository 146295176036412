import { useEffect, type PropsWithChildren, useState } from "react";

import dynamic from "next/dynamic";

import { Footer } from "./footer";
import { Main } from "./main";
import { Navbar } from "./navbar";
import MaintenancePage from "~/components/maintenance/maintenance";
import { useAuthV2 } from "~/hooksV2";

const DynamicNewsletter = dynamic(
  async () => {
    const component = await import("../newsletter");
    return component.Newsletter;
  },
  { ssr: false }
);

const DynamicToaster = dynamic(
  async () => {
    const component = await import("react-hot-toast");
    return component.Toaster;
  },
  {
    ssr: false,
  }
);


export const Layout = (props:any) => {
  const { children, isPageShow = false } = props;

  const [maintenanceMode, setMaintenanceMode] = useState(false)
  const { user } = useAuthV2()
  
  const getMaintenaceMode = async ()=>{
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/get-maintenance`,
    )

    const data = await response.json()

    if(data?.maintanance_is_on){
       if(data?.allowed_users?.includes(user?.databaseId?.toString())){
          setMaintenanceMode(false)
       } else {
          setMaintenanceMode(true)
       }
    } else {
      setMaintenanceMode(false)
    }
  }

  useEffect(()=>{
    getMaintenaceMode()
  },[,user, user?.databaseId])

  return (
    <div>
      { maintenanceMode && !isPageShow ? (
        <MaintenancePage />
      ) : (
        <>
          <Navbar />
          <Main>{children}</Main>
          <DynamicNewsletter />
          <DynamicToaster position="bottom-right" />
          <Footer />
        </>
      )}
    </div>
  );
};
